































































































import Vue from 'vue';
import 'bootstrap-vue';
import Component from 'vue-class-component';
import {
    ProjectStatusList,
    ProjectTypeList,
    ProjectObjectList,
    CapacityIndicatorsFacilitiesList,
    ProjectPowerList, ProjectType
} from './bip-types';

// декоратор @Component указывает, что класс — это компонент Vue
@Component({
    name: 'bip-config',
    components: {}
})

export default class BipConfig extends Vue {
    public $refs!: {
        projectTypeModal: HTMLFormElement;
    };
    private projectStatusList = ProjectStatusList;
    private projectTypeList = ProjectTypeList;
    private projectObjectList = ProjectObjectList;
    private projectPowerList = ProjectPowerList;
    private capacityIndicatorsFacilitiesList = CapacityIndicatorsFacilitiesList;
    public selectedProjectType: any | ProjectType = {};
    private projectTypeListFields: any[] = [
        {
            key: 'id',
            label: 'ID'
        },
        {
            key: 'code',
            label: 'Код'
        },
        {
            key: 'nameRu',
            label: 'Наименовние (ru)'
        },
        {
            key: 'nameKk',
            label: 'Наименовние (kk)'
        },
        {
            key: 'more',
            label: '...'
        }
    ];
    private projectObjectListFields: any[] = [
        {
            key: 'id',
            label: 'ID'
        },
        {
            key: 'code',
            label: 'Код'
        },
        {
            key: 'nameRu',
            label: 'Наименовние (ru)'
        },
        {
            key: 'nameKk',
            label: 'Наименовние (kk)'
        },
        {
            key: 'direction',
            label: 'Направление'
        },
        {
            key: 'more',
            label: '...'
        }
    ];
    private capacityIndicatorsFacilitiesListFields: any[] = [
        {
            key: 'id',
            label: 'Id'
        },
        {
            key: 'code',
            label: 'Код'
        },
        {
            key: 'objectCode',
            label: 'Связанные объекты'
        },
        {
            key: 'nameRu',
            label: 'Наименование'
        },
        {
            key: 'unit',
            label: 'Единица измерения'
        },
        {
            key: 'data_type',
            label: 'Тип данных'
        },
        {
            key: 'source_type',
            label: 'Источник данных'
        },
        {
            key: 'calc_type',
            label: 'Вид формулы'
        },
        {
            key: 'criteria_type',
            label: 'Тип критерия'
        },
        {
            key: 'more',
            label: '...'
        }
    ];
    private dataTypeList: any[] = [
        {
            value: 'number',
            text: 'Числовое'
        },
        {
            value: 'boolean',
            text: 'Логическое'
        }
    ];
    private openProjectType(item: ProjectType) {
        // Object.assign(this.selectedProjectType, item);
        this.selectedProjectType = {
            ...item
        };
        this.$refs.projectTypeModal.show();
    }
}
