import { render, staticRenderFns } from "./bip-config.vue?vue&type=template&id=64de1d8f&scoped=true&"
import script from "./bip-config.vue?vue&type=script&lang=ts&"
export * from "./bip-config.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64de1d8f",
  null
  
)

export default component.exports